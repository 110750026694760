const local = {
  directory_search_api_root:
    "https://directorysearch-dev.api.fsm.northwestern.edu",
  directory_search_api_key: "akFFijCyOP2IoOKXuHud9a8cfTOByIerNZghUKj4",
  directory_search_export_name: "directorySearchExport.csv",
  msalConfig: {
    auth: {
      clientId: "1001af91-d7b4-4cff-a94c-e6639e2b42c2",
      redirectUri: "http://localhost:3000/people",
      authority: "https://login.microsoftonline.com/nuwildcat.onmicrosoft.com",
      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri: "http://localhost:3000",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
  loginRequest: {
    scopes: ["User.Read"],
    extraQueryParameters: { domain_hint: "northwestern.edu" },
  },
  graphConfig: {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  },
};

const dev = {
  directory_search_api_root:
    "https://directorysearch-dev.api.fsm.northwestern.edu",
  directory_search_api_key: "akFFijCyOP2IoOKXuHud9a8cfTOByIerNZghUKj4",
  directory_search_export_name: "directorySearchExport.csv",
  msalConfig: {
    auth: {
      clientId: "6e1f169a-8c2d-4727-b16d-1940708ac053",
      redirectUri: "https://directorysearch-dev.fsm.northwestern.edu/people",
      authority: "https://login.microsoftonline.com/nuwildcat.onmicrosoft.com",
      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri:
        "https://directorysearch-dev.fsm.northwestern.edu/",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
  loginRequest: {
    scopes: ["User.Read"],
    extraQueryParameters: { domain_hint: "northwestern.edu" },
  },
  graphConfig: {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  },
};

const uat = {
  directory_search_api_root:
    "https://directorysearch-uat.api.fsm.northwestern.edu",
  directory_search_api_key: "Py8SDT5Hwr7zkGoxyOfNF1OiTYoeUQRaa5dbmlRH",
  directory_search_export_name: "directorySearchExport.csv",
  msalConfig: {
    auth: {
      clientId: "97e182f6-e5c5-4307-a6f7-5a3a1798cc0f",
      redirectUri: "https://directorysearch-uat.fsm.northwestern.edu/people",
      authority: "https://login.microsoftonline.com/nuwildcat.onmicrosoft.com",
      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri:
        "https://directorysearch-uat.fsm.northwestern.edu/",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
  loginRequest: {
    scopes: ["User.Read"],
    extraQueryParameters: { domain_hint: "northwestern.edu" },
  },
  graphConfig: {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  },
};

const prod = {
  directory_search_api_root: "https://directorysearch.api.fsm.northwestern.edu",
  directory_search_api_key: "sBQWe54xjoa2WVmczDzC49g7sWdiwLvc6QGw8LxH",
  directory_search_export_name: "directorySearchExport.csv",
  msalConfig: {
    auth: {
      clientId: "c4c6b7b3-e72c-4165-907f-8d1fce759a9c",
      redirectUri: "https://directorysearch.fsm.northwestern.edu/people",
      authority: "https://login.microsoftonline.com/nuwildcat.onmicrosoft.com",
      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri: "https://directorysearch.fsm.northwestern.edu/",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
  loginRequest: {
    scopes: ["User.Read"],
    extraQueryParameters: { domain_hint: "northwestern.edu" },
  },
  graphConfig: {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  },
};

let config = null;

switch (process.env.REACT_APP_ENV.toLowerCase()) {
  case "local":
    config = local;
    break;
  case "dev":
    config = dev;
    break;
  case "uat":
    config = uat;
    break;
  case "prod":
    config = prod;
    break;
  default:
    alert("Invalid config!");
    break;
}

let configExport = {
  ...config,
};

export default configExport;
