import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  TextField,
  CircularProgress,
  Chip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginRight: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formWrapper: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

export default function SimpleSelect({ departments, types, filterChange }) {
  const classes = useStyles();
  const [filters, setFilters] = useState("");

  useEffect(() => {
    if (filters !== "") filterChange(filters);
  }, [filters, filterChange]);

  return (
    <Paper style={{ padding: 16 }}>
      <Typography variant="h6" className={classes.title}>
        Filtering
      </Typography>
      <div className={classes.formWrapper}>
        <Grid container spacing={6}>
          <Grid item md={6} sm={6} xs={12}>
            <Autocomplete
              multiple
              id="departments"
              options={!departments.loading ? departments.data : []}
              getOptionLabel={(option) => option.deptname}
              defaultValue={[]}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={
                    !departments.loading ? (
                      !departments.error ? (
                        "Departments"
                      ) : (
                        departments.message
                      )
                    ) : (
                      <CircularProgress size={16} />
                    )
                  }
                  placeholder="Department Name"
                />
              )}
              renderTags={(value, getTagProps) => (
                <div style={{ width: "100%" }}>
                  {value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.deptname}
                      {...getTagProps({ index })}
                    />
                  ))}
                </div>
              )}
              onChange={(event, newValue) => {
                setFilters({
                  ...filters,
                  departments: [
                    ...new Set(newValue.map((newVal) => newVal.groupid)),
                  ],
                });
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Autocomplete
              multiple
              id="types"
              options={!types.loading ? types.data : []}
              getOptionLabel={(option) =>
                `${option.position_desc} | ${option.category_desc}`
              }
              defaultValue={[]}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={
                    !types.loading ? (
                      !types.error ? (
                        "Types"
                      ) : (
                        types.message
                      )
                    ) : (
                      <CircularProgress size={16} />
                    )
                  }
                  placeholder="Type Name"
                />
              )}
              renderTags={(value, getTagProps) => (
                <div style={{ width: "100%" }}>
                  {value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={`${option.position_desc} | ${option.category_desc}`}
                      {...getTagProps({ index })}
                    />
                  ))}
                </div>
              )}
              onChange={(event, newValue) => {
                setFilters({
                  ...filters,
                  types: [
                    ...new Set(
                      newValue.map((newVal) => newVal.type_id.toString())
                    ),
                  ],
                });
              }}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              className={classes.formControl}
              id="first-name"
              label="First Name"
              variant="outlined"
              onChange={(event) =>
                setFilters({
                  ...filters,
                  firstName: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              className={classes.formControl}
              id="last-name"
              label="Last Name"
              variant="outlined"
              onChange={(event) =>
                setFilters({
                  ...filters,
                  lastName: event.target.value,
                })
              }
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
