import React from "react";
import { Container, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FSMLogo from "../../assets/Feinberg-linear-white.png";
import BGImage from "../../assets/bg-landing.jpg";
import { useMsal } from "@azure/msal-react";
import config from "../config/Config";
import { useIsAuthenticated } from "@azure/msal-react";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  homeOuter: {
    backgroundImage: `url(${BGImage})`,
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  fsmLogo: {
    position: "absolute",
    top: theme.spacing(4),
    left: "calc(50% - 90px)",
    width: 180,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  dirSearchLogo: {
    ...theme.typography.campton,
    position: "relative",
    fontSize: 52,
    lineHeight: "52px",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
      lineHeight: "40px",
    },
  },
  introText: {
    marginBottom: 12,
    fontSize: 20,
    fontWeight: 200,
  },
  helperText: {
    maxWidth: 400,
    margin: "40px auto 0 auto",
    fontWeight: 200,
    textAlign: "left",
  },
  homeLoginWrap: {
    color: "white",
  },
  navLinkOverride: {
    display: "block",
    margin: "24px 0",
    textDecoration: "none",
  },
  buttonOverride: {
    minWidth: 200,
  },
  gridContainer: {
    height: "80%",
    width: "80%",
    borderRadius: "12px",
    boxShadow: "0px 6px 12px 0px rgba(0,0,0,0.6)",
    [theme.breakpoints.down("sm")]: {
      height: "90%",
      width: "90%",
    },
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    "&.left": {
      borderRadius: "12px 0 0 12px",
      position: "relative",
      backgroundImage: "linear-gradient(#4E2A84, #30104e)",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "12px 12px 0 0",
      },
    },
    "&.right": {
      borderRadius: "0 12px 12px 0",
      backgroundColor: "white",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "0 0 12px 12px",
      },
    },
  },
}));

function handleLogin(instance) {
  instance.loginRedirect(config.loginRequest).catch((e) => {
    console.error(e);
  });
}

function handleLogout(instance) {
  instance.logoutRedirect().catch((e) => {
    console.error(e);
  });
}

export default function App() {
  const classes = useStyles();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  return (
    <React.Fragment>
      <Container
        className={classes.homeOuter}
        disableGutters={true}
        maxWidth={false}
      >
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            className={`${classes.gridItem} left`}
          >
            <img src={FSMLogo} alt="FSM Logo" className={classes.fsmLogo} />
            <div className={classes.homeLoginWrap}>
              <div className={classes.introText}>Welcome to the FSM</div>
              <div className={classes.dirSearchLogo}>Directory Search</div>
              <div className={classes.helperText}>
                The FSM Directory Search allows users to search, filter, and
                export staff, student, and faculty data. This data is sourced
                from MyHR, and is updated daily.
                <br></br>
                <br></br>
                If you have any questions about this site, please email us at{" "}
                <a href="mailto:mis-web-dev@northwestern.edu">
                  <b>mis-web-dev@northwestern.edu</b>
                </a>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            className={`${classes.gridItem} right`}
          >
            {!isAuthenticated ? (
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.buttonOverride}
                onClick={() => handleLogin(instance)}
              >
                Login
              </Button>
            ) : (
              <div>
                <NavLink to="/people" className={classes.navLinkOverride}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.buttonOverride}
                    onClick={() => handleLogin(instance)}
                  >
                    Go to Search
                  </Button>
                </NavLink>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  className={classes.buttonOverride}
                  onClick={() => handleLogout(instance)}
                >
                  Logout
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
