import { createMuiTheme } from "@material-ui/core/styles";
import AkkuratPro from "./assets/fonts/AkkuratProRegular.woff";
import AkkuratProLight from "./assets/fonts/AkkuratProLight.woff";
import AkkuratProBold from "./assets/fonts/AkkuratProBold.woff";
import CamptonBold from "./assets/fonts/CamptonBold.woff";

const akkuratPro = {
  fontFamily: "AkkuratPro",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('AkkuratPro'),
    local('AkkuratPro-Regular'),
    url(${AkkuratPro}) format('woff')
  `,
};

const akkuratProLight = {
  fontFamily: "AkkuratPro",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 200,
  src: `
    local('AkkuratPro'),
    local('AkkuratPro-Light'),
    url(${AkkuratProLight}) format('woff')
  `,
};

const akkuratProBold = {
  fontFamily: "AkkuratPro",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 500,
  src: `
    local('AkkuratPro'),
    local('AkkuratPro-Bold'),
    url(${AkkuratProBold}) format('woff')
  `,
};

const camptonBold = {
  fontFamily: "Campton",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 700,
  src: `
    local('Campton'),
    local('Campton-Bold'),
    url(${CamptonBold}) format('woff')
  `,
};

const theme = createMuiTheme({
  typography: {
    fontFamily: ["AkkuratPro", "Roboto", "Arial", "Sans-Serif"].join(","),
    campton: {
      fontFamily: ["Campton", "Serif"].join(","),
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          akkuratPro,
          camptonBold,
          akkuratProLight,
          akkuratProBold,
        ],
        a: {
          color: "white",
          textDecoration: "none",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#4e2a84",
      light: "#b295dd",
    },
    secondary: {
      main: "#fff",
    },
    success: {
      main: "#58B947",
    },
    error: {
      main: "#EF553F",
    },
  },
});

export default theme;
