import React, { useState, useEffect, useRef, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExportIcon from "@material-ui/icons/GetApp";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Config from "../config/Config";
import Alert from "@material-ui/lab/Alert";
import { CSVLink } from "react-csv";

const columnsData = [
  { id: "netid", label: "NetID", minWidth: 80 },
  { id: "lastname", label: "Last Name", minWidth: 170 },
  { id: "firstname", label: "First Name", minWidth: 170 },
  { id: "emplid", label: "Employee ID", minWidth: 100 },
  { id: "deptname", label: "Department Name", minWidth: 170 },
  { id: "position_desc", label: "Position Description", minWidth: 170 },
  { id: "category_desc", label: "Category Description", minWidth: 170 },
  { id: "apptstartdate", label: "Appointment Start Date", minWidth: 100 },
  { id: "apptenddate", label: "Appointment End Date", minWidth: 100 },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(8),
  },
  container: {},
  progressWrap: {
    height: "600px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alert: {
    justifyContent: "center",
  },
  sticky: {
    position: "sticky",
    left: 0,
    background: "white",
    borderRight: `2px solid ${theme.palette.primary.light}`,
  },
  headerSticky: {
    zIndex: 1001,
  },
  tableFooterWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px`,
  },
  csvLinkWrap: {
    textDecoration: "none",
  },
  disabled: {
    cursor: "default",
  },
  tableDisclaimer: {
    position: "sticky",
    zIndex: 1002,
    top: 0,
    padding: theme.spacing(2),
    fontWeight: 600,
    textAlign: "center",
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function StickyHeadTable({ filters }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataLoading, setDataLoading] = useState(true);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const exportLimit = 1200;
  const [exportData, setExportData] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const csvLink = useRef(null);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const convertDate = (dateString) => {
    var date = new Date(dateString);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    if (day < 10) day = "0" + day;
    if (month < 10) month = "0" + month;
    if (hour < 10) hour = "0" + hour;
    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;

    let roleMarkup =
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      seconds;

    return roleMarkup;
  };

  const transformResponse = useCallback((response) => {
    return response.map((elem) => {
      return {
        ...elem,
        apptstartdate: elem.apptstartdate && convertDate(elem.apptstartdate),
        apptenddate: elem.apptenddate && convertDate(elem.apptenddate),
        effectivedate: elem.effectivedate && convertDate(elem.effectivedate),
        nulatesthiredate:
          elem.nulatesthiredate && convertDate(elem.nulatesthiredate),
        create_date: elem.create_date && convertDate(elem.create_date),
        modify_date: elem.modify_date && convertDate(elem.modify_date),
      };
    });
  }, []);

  useEffect(() => {
    setDataLoading(true);

    axios
      .get(
        `${Config.directory_search_api_root}/search/people?offset=0&limit=${
          rowsPerPage * (page + 1)
        }${filters.departments ? "&groupid=" + filters.departments : ""}${
          filters.types ? "&typeid=" + filters.types : ""
        }${filters.firstName ? "&fname=" + filters.firstName : ""}${
          filters.lastName ? "&lname=" + filters.lastName : ""
        }`,
        {
          headers: {
            "x-api-key": Config.directory_search_api_key,
          },
        }
      )
      .then((response) => {
        setData(transformResponse(response.data.data));
        setCount(response.data._totalRecords);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setDataLoading(false));
  }, [page, rowsPerPage, filters, transformResponse]);

  const handleExport = (event) => {
    setExportLoading(true);
    axios
      .get(
        `${Config.directory_search_api_root}/search/people?${
          filters.departments ? "&groupid=" + filters.departments : ""
        }${filters.types ? "&typeid=" + filters.types : ""}${
          filters.firstName ? "&fname=" + filters.firstName : ""
        }${filters.lastName ? "&lname=" + filters.lastName : ""}`,
        {
          headers: {
            "x-api-key": Config.directory_search_api_key,
          },
        }
      )
      .then((response) => {
        setExportData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setExportLoading(false);
        csvLink.current.link.click();
      });
  };

  return (
    <React.Fragment>
      {dataLoading ? (
        <div className={`${classes.root} ${classes.progressWrap}`}>
          <CircularProgress />
        </div>
      ) : data.length > 0 ? (
        <Paper className={classes.root}>
          <div className={classes.tableDisclaimer}>
            Please note that each row represents a unique appointment for an FSM
            employee.
          </div>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columnsData.map((column, index) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      className={
                        index === 0
                          ? `${classes.sticky} ${classes.headerSticky}`
                          : ""
                      }
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.record_id}
                        onClick={() => console.log(row)}
                      >
                        {columnsData.map((column, index) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className={index === 0 ? classes.sticky : ""}
                            >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.tableFooterWrap}>
            {count > exportLimit ? (
              <Tooltip title="There is a limit of 1200 records when exporting.">
                <span>
                  {/* span wrapper required to enable tooltip on a disabled button */}
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<ExportIcon />}
                    disabled
                  >
                    Export to CSV
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={
                    exportLoading ? (
                      <CircularProgress size={16} color={"secondary"} />
                    ) : (
                      <ExportIcon />
                    )
                  }
                  disabled={count > exportLimit}
                  onClick={(event) => handleExport()}
                >
                  Export to CSV
                </Button>
                <CSVLink
                  ref={csvLink}
                  data={transformResponse(exportData)}
                  filename={Config.directory_search_export_name}
                />
              </>
            )}
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={parseInt(count)}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </Paper>
      ) : (
        <Alert severity="error" className={classes.alert}>
          No records found. Please try different filtering criteria.
        </Alert>
      )}
    </React.Fragment>
  );
}
