import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableWrap: {
    marginTop: theme.spacing(4),
  },
  text: {
    textAlign: "center",
  },
}));

export default function App() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container className={classes.tableWrap} disableGutters={true}>
        <Typography variant="h6" className={classes.text}>
          Page could not be found.
        </Typography>
      </Container>
    </React.Fragment>
  );
}
