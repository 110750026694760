import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import { Popover } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { useMsal } from "@azure/msal-react";
import config from "../config/Config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  avatar: {
    cursor: "pointer",
  },
  popOver: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  userName: {
    fontWeight: "bold",
  },
}));

const getInitials = (name) => {
  // https://stackoverflow.com/questions/33076177/getting-name-initials-using-js

  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();

  return initials;
};

function handleLogout(instance) {
  instance.logoutRedirect().catch((e) => {
    console.error(e);
  });
}

export default function ButtonAppBar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const [userPhoto, setUserPhoto] = useState(null);
  const [userTitle, setUserTitle] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const userName = accounts[0] && accounts[0].name;

  useEffect(() => {
    if (accounts[0] && instance) {
      const request = {
        ...config.loginRequest,
        account: accounts[0],
      };

      instance
        .acquireTokenSilent(request)
        .then((response) => {
          setAccessToken(response.accessToken);
        })
        .catch((e) => {
          instance.acquireTokenPopup(request).then((response) => {
            setAccessToken(response.accessToken);
          });
        });
    }
  }, [accounts, instance]);

  useEffect(() => {
    if (accessToken) {
      const headers = new Headers();
      const bearer = `Bearer ${accessToken}`;

      headers.append("Authorization", bearer);

      const options = {
        method: "GET",
        headers: headers,
      };

      fetch(`${config.graphConfig.graphMeEndpoint}`, options)
        .then((response) => response.json())
        .then((data) => setUserTitle(data.jobTitle))
        .catch((error) => console.log(error));

      fetch(`${config.graphConfig.graphMeEndpoint}/photo/$value`, options)
        .then((response) => response.blob())
        .then((data) => setUserPhoto(URL.createObjectURL(data)))
        .catch((error) => console.log(error));
    }
  }, [accessToken]);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <NavLink to="/">FSM Directory Search</NavLink>
          </Typography>
          {userName ? (
            <>
              <Avatar
                className={classes.avatar}
                aria-describedby={id}
                onClick={handleClick}
                src={userPhoto}
              >
                {getInitials(userName)}
              </Avatar>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                className={classes.popOver}
              >
                <div className={classes.popOver}>
                  <Typography className={classes.userName}>
                    {userName}
                  </Typography>
                  {userTitle && (
                    <Typography variant="body2">{userTitle}</Typography>
                  )}
                  <Button
                    color="inherit"
                    onClick={() => handleLogout(instance)}
                  >
                    Logout
                  </Button>
                </div>
              </Popover>
            </>
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
