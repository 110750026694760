import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import axios from "axios";
import AddIcon from "@material-ui/icons/Add";
import TypesModal from "../modals/TypesModal";
import Config from "../config/Config";

const columnsData = [
  { id: "type_id", label: "Type ID", minWidth: 80 },
  { id: "position_type", label: "Position Type", minWidth: 120 },
  { id: "position_desc", label: "Position Desc", minWidth: 120 },
  { id: "category_type", label: "Category Type", minWidth: 120 },
  { id: "category_desc", label: "Category Description", minWidth: 170 },
  { id: "status", label: "Status", minWidth: 80 },
  { id: "create_user", label: "Create User", minWidth: 120 },
  { id: "create_date", label: "Create Date", minWidth: 120 },
  { id: "modify_user", label: "Modify User", minWidth: 120 },
  { id: "modify_date", label: "Modify Date", minWidth: 120 },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(8),
  },
  container: {
    maxHeight: "600px",
  },
  progressWrap: {
    height: "600px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonWrap: {
    margin: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  sticky: {
    position: "sticky",
    left: 0,
    background: "white",
    borderRight: `2px solid ${theme.palette.primary.main}`,
  },
  headerSticky: {
    zIndex: 1001,
  },
}));

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataLoading, setDataLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [dialogAction, setDialogAction] = useState("");

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRefreshTable = useCallback(() => {
    setDataLoading(true);

    axios
      .get(`${Config.directory_search_api_root}/search/types`, {
        headers: {
          "x-api-key": Config.directory_search_api_key,
          "Cache-Control": "max-age=0",
        },
      })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setDataLoading(false));
  }, []);

  useEffect(() => {
    handleRefreshTable();
  }, [handleRefreshTable]);

  const handleTypeEdit = (row) => {
    setDialogAction("Edit");
    setDialogData(row);
    setDialogOpen(true);
  };

  const handleTypeAdd = (event) => {
    setDialogAction("Add");
    setDialogData({});
    setDialogOpen(true);
  };

  return (
    <React.Fragment>
      {dataLoading ? (
        <div className={`${classes.root} ${classes.progressWrap}`}>
          <CircularProgress />
        </div>
      ) : (
        <React.Fragment>
          <Container className={classes.buttonWrap}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={handleTypeAdd}
            >
              Add Type
            </Button>
          </Container>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columnsData.map((column, index) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        className={
                          index === 0
                            ? `${classes.sticky} ${classes.headerSticky}`
                            : ""
                        }
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.type_id}
                          onClick={() => handleTypeEdit(row)}
                        >
                          {columnsData.map((column, index) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                className={index === 0 ? classes.sticky : ""}
                              >
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </React.Fragment>
      )}
      <TypesModal
        dialogAction={dialogAction}
        dialogOpen={dialogOpen}
        closeDialog={(e) => {
          setDialogData("");
          setDialogOpen(false);
        }}
        typeData={dialogData}
        refreshTable={handleRefreshTable}
      />
    </React.Fragment>
  );
}
