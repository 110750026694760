import React, { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import Table from "../tables/PeopleTable";
import FilterMenu from "../filtermenu/FilterMenu";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Config from "../config/Config";

const useStyles = makeStyles((theme) => ({
  tableWrap: {
    marginTop: theme.spacing(4),
  },
}));

export default function App() {
  const classes = useStyles();
  const [departments, setDepartments] = useState({
    loading: true,
    error: false,
    message: "Loading...",
    data: [],
  });
  const [types, setTypes] = useState({
    loading: true,
    error: false,
    message: "Loading...",
    data: [],
  });
  const [filters, setFilters] = useState("");

  useEffect(() => {
    axios
      .get(`${Config.directory_search_api_root}/search/departments`, {
        headers: {
          "x-api-key": Config.directory_search_api_key,
        },
      })
      .then((response) => {
        setDepartments({
          loading: false,
          error: false,
          data: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
        setDepartments({
          loading: false,
          error: true,
          message: "There was an error getting the department list!",
          data: [],
        });
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${Config.directory_search_api_root}/search/types`, {
        headers: {
          "x-api-key": Config.directory_search_api_key,
        },
      })
      .then((response) => {
        setTypes({
          loading: false,
          error: false,
          data: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
        setTypes({
          loading: false,
          error: true,
          message: "There was an error getting the type list!",
          data: [],
        });
      });
  }, []);

  return (
    <React.Fragment>
      <Container className={classes.tableWrap} disableGutters={true}>
        <FilterMenu
          departments={departments}
          types={types}
          filterChange={(filters) => setFilters(filters)}
        />
      </Container>
      <Container className={classes.tableWrap} disableGutters={true}>
        <Table filters={filters} />
      </Container>
    </React.Fragment>
  );
}
