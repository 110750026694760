import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import SaveIcon from "@material-ui/icons/Save";
import ErrorIcon from "@material-ui/icons/Error";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";
import Config from "../config/Config";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(4),
  },
  modalMessage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  },
}));

const TypesModal = ({
  dialogAction,
  dialogOpen,
  closeDialog,
  typeData,
  refreshTable,
}) => {
  const classes = useStyles();
  const [dialogData, setDialogData] = useState("");
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    error: false,
    success: false,
    message: "",
  });

  useEffect(() => {
    if (typeData) setDialogData(typeData);
  }, [typeData]);

  const handleClose = (event) => {
    event.preventDefault();
    closeDialog();
    setSubmitStatus({
      loading: false,
      error: false,
      success: false,
      message: "",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitStatus({ loading: true });

    if (dialogAction === "Add") {
      let message = {
        postype: dialogData.position_type,
        posdesc: dialogData.position_desc,
        cattype: dialogData.category_type,
        catdesc: dialogData.category_desc,
        status: dialogData.status,
        create_user: "Matt",
      };

      axios
        .post(`${Config.directory_search_api_root}/search/types`, message, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": Config.directory_search_api_key,
          },
        })
        .then((response) => {
          if (!response.data.error) {
            setSubmitStatus({
              loading: false,
              error: false,
              success: true,
              message: "The directory search type was successfully added!",
            });
            refreshTable();
          } else {
            // Internal Error
            setSubmitStatus({
              loading: false,
              error: true,
              success: false,
              message:
                "There was an error adding the directory search type: " +
                response.data.error,
            });
          }
        })
        .catch((error) => {
          setSubmitStatus({
            loading: false,
            error: true,
            success: false,
            message: "There was an error adding the directory search type!",
          });
          console.log(error);
        });
    } else if (dialogAction === "Edit") {
      let message = {
        postype: dialogData.position_type,
        posdesc: dialogData.position_desc,
        cattype: dialogData.category_type,
        catdesc: dialogData.category_desc,
        status: dialogData.status,
        modify_user: "Matt",
      };

      axios
        .put(
          `${Config.directory_search_api_root}/search/types/${dialogData.type_id}`,
          message,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": Config.directory_search_api_key,
            },
          }
        )
        .then((response) => {
          if (!response.data.error) {
            setSubmitStatus({
              loading: false,
              error: false,
              success: true,
              message: "The directory search type was successfully updated!",
            });
            refreshTable();
          } else {
            // Internal Error
            setSubmitStatus({
              loading: false,
              error: true,
              success: false,
              message:
                "There was an error updating the directory search type: " +
                response.data.error,
            });
          }
        })
        .catch((error) => {
          setSubmitStatus({
            loading: false,
            error: true,
            success: false,
            message: "There was an error adding the directory search type!",
          });
          console.log(error);
        });
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="directory-search-title"
      open={dialogOpen}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="directory-search-title">
          {dialogAction} Type
          {dialogAction === "Edit" && `: ${dialogData.type_id}`}
        </DialogTitle>
        <DialogContent>
          <TextField
            className={classes.textField}
            variant="outlined"
            id="category_desc"
            label="Category Description"
            type="text"
            fullWidth
            value={dialogData.category_desc || ""}
            onChange={(e) =>
              setDialogData({
                ...dialogData,
                category_desc: e.target.value,
              })
            }
            required
          />
          <TextField
            className={classes.textField}
            variant="outlined"
            id="category_type"
            label="Category Type"
            type="text"
            fullWidth
            value={dialogData.category_type || ""}
            onChange={(e) =>
              setDialogData({
                ...dialogData,
                category_type: e.target.value,
              })
            }
            required
          />
          <TextField
            className={classes.textField}
            variant="outlined"
            id="position_desc"
            label="Position Description"
            type="text"
            fullWidth
            value={dialogData.position_desc || ""}
            onChange={(e) =>
              setDialogData({
                ...dialogData,
                position_desc: e.target.value,
              })
            }
            required
          />
          <TextField
            className={classes.textField}
            variant="outlined"
            id="position_type"
            label="Position Type"
            type="text"
            fullWidth
            value={dialogData.position_type || ""}
            onChange={(e) =>
              setDialogData({
                ...dialogData,
                position_type: e.target.value,
              })
            }
            required
          />
          <TextField
            className={classes.textField}
            variant="outlined"
            select
            label="Status"
            value={dialogData.status || ""}
            onChange={(e) =>
              setDialogData({
                ...dialogData,
                status: e.target.value,
              })
            }
            fullWidth
            required
          >
            <MenuItem key="A" value="A">
              Active
            </MenuItem>
            <MenuItem key="I" value="I">
              Inactive
            </MenuItem>
          </TextField>
          <Typography
            className={`${classes.modalMessage} ${
              submitStatus.error ? classes.error : ""
            } ${submitStatus.success ? classes.success : ""}`}
          >
            {submitStatus.error && <ErrorIcon />}
            {submitStatus.success && <CheckIcon />}
            {submitStatus.message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={
              submitStatus.loading ? (
                <CircularProgress size={16} color="secondary" />
              ) : (
                <SaveIcon />
              )
            }
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TypesModal;
