import React from "react";
import AppHeader from "../appheader/AppHeader";
import People from "../people/People";
import Types from "../types/Types";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import NavTabs from "./NavTabs";
import NotFound from "./NotFound";
import Home from "../home/Home";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

export default function App() {
  return (
    <>
      <AuthenticatedTemplate>
        <BrowserRouter>
          {/* <NavTabs /> */}
          <Switch>
            <Route exact={true} path="/" component={Home} />
            <Route path="/people">
              <>
                <AppHeader />
                <People />
              </>
            </Route>
            <Route path="/types">
              <>
                <AppHeader />
                <Types />
              </>
            </Route>
            <Route>
              <AppHeader />
              <NotFound />
            </Route>
          </Switch>
        </BrowserRouter>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Home />
      </UnauthenticatedTemplate>
    </>
  );
}
