import React from "react";
import { Container } from "@material-ui/core";
import Table from "../tables/TypeTable";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableWrap: {
    marginTop: theme.spacing(4),
  },
}));

export default function App() {
  const classes = useStyles();

  return (
    <Container className={classes.tableWrap} disableGutters={true}>
      <Table />
    </Container>
  );
}
